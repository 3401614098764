import React from 'react';
import './VideoSection.css';
import videotrailerMP4 from '../Trailer.mp4';
import videotrailerWebM from '../Trailer.webm';


function VideoSection() {
  return (
    <section className="video-section">
      <div className="container">
        <h1>Unlock the Power of AI for Grant Applications</h1>
        <p>Discover how Grants.com can simplify your grant application process with cutting-edge AI technology.</p>
        <p>Our platform provides personalized assistance, helping you find and apply for the right grants efficiently and effectively.</p> 
        <p>Stay tuned for our launch and be the first to experience the future of grant applications. Sign up for updates and early access!</p>
        <div className="video-container">
          <video controls playsInline muted>
            <source src={videotrailerMP4} type="video/mp4" />
            <source src={videotrailerWebM} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="cta-buttons">
          <a href="mailto:support@grants.com" className="button">Contact Us</a>
        </div>
      </div>
    </section>
  );
}

export default VideoSection;
