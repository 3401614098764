import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VideoSection from './components/VideoSection';
import logo from './logogrants.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import ReactGA from 'react-ga4';

import './App.css';  // Importe o arquivo CSS

document.title = "Grants.com"; // Altera o título do documento
<meta name="description" content="Discover a comprehensive database of grants and leverage our AI-powered assistant to streamline your grant application process. Sign up for personalized grant opportunities and expert guidance at Grants.com." />

const trackingId = "G-CV084VDWK7"; // Substitua pelo seu ID de rastreamento do Google Analytics

const App = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        ReactGA.initialize([{ trackingId }]);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const SocialLinks = () => {
        return (
            <div style={styles.socialLinks}>
                <a href="https://instagram.com" style={styles.socialLink}>
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://linkedin.com" style={styles.socialLink}>
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="https://twitter.com" style={styles.socialLink}>
                    <FontAwesomeIcon icon={faTwitter} />
                </a>
            </div>
        );
    }

    return (
        <Router>
            <div style={styles.container}>
                <header className="header">
                    <div className="logo-container">
                        <img src={logo} alt="Logo" className="logo" />
                        <h1 className="title"></h1>
                    </div>
                    <nav className="nav">
                        <button className="hamburger" onClick={toggleMenu}>
                            ☰
                        </button>
                    </nav>
                </header>
                <div style={styles.content}>
                    <Routes>
                        <Route path="/" element={<VideoSection />} />
                    </Routes>
                </div>
<footer style={styles.footer}>
    <div style={styles.footerContent}>
     
    </div>
    <div style={styles.footerBottom}>
        <div style={styles.footerLeftText}>
            <p>&copy; 2024 Grants, All Rights Reserved</p>
        </div>
        <div style={styles.footerRight}>
            <p>Hodie LLC</p>
            <p>701 NE 136th Ave., Suite 200</p>
            <p>Vancouver, Washington, 98684</p>
        </div>
    </div>
</footer>
 

				
				
            </div>
        </Router>
    );
};

const styles = {
    container: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    header: {
        backgroundColor: '#f0f0f0',
        padding: '10px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        width: '100%',
        boxSizing: 'border-box'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        height: '40px',
        marginRight: '10px'
    },
    title: {
        fontSize: '24px',
        color: '#333',
        margin: 0
    },
    nav: {
        display: 'flex',
        alignItems: 'center'
    },
    navList: {
        listStyle: 'none',
        display: 'flex',
        padding: 0,
        margin: 0
    },
    navItem: {
        margin: '0 15px'
    },
    navLink: {
        color: '#333',
        textDecoration: 'none',
        fontSize: '18px',
        fontWeight: 'bold',
        transition: 'color 0.3s ease'
    },
    contactButton: {
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#0078FF',
        color: 'white',
        cursor: 'pointer',
        fontSize: '16px',
        marginLeft: '20px'
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(to bottom, #e9f8fd 0%, white 70%, white 30%)'
    },
 
    footer: {
        padding: '20px 0',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    footerContent: {
        width: '100%',
        maxWidth: '1200px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0 20px',
        boxSizing: 'border-box'
    },
    footerLeft: {
        display: 'flex',
        
        alignItems: 'flex-start',
        maxWidth: '300px'
    },
    footerLogo: {
        height: '60px',
        marginBottom: '10px'
    },
    footerLinks: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '60%'
    },
    footerColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: '30px'
    },
    footerBottom: {
        width: '100%',
        maxWidth: '1200px',
        display: 'flex',
        backgroundColor: '#e9f8fd',
        borderRadius: '20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        padding: '10px 20px',  // Ajuste do padding
        boxSizing: 'border-box'
    },
    footerLeftText: {
        // Mantém o texto do lado esquerdo na mesma posição
    },
    footerRight: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end', // Alinha o conteúdo à direita
        textAlign: 'right' // Alinha o texto à direita
    },

    socialLinks: {
        display: 'flex',
        alignItems: 'center'
    },
    socialLink: {
        color: '#333',
        textDecoration: 'none',
        marginLeft: '15px',
        fontSize: '14px',
        transition: 'color 0.3s ease'
    },
    '@media (max-width: 768px)': {
        header: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        nav: {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        hamburger: {
            display: 'block',
            background: 'none',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
            padding: '0',
            marginLeft: 'auto'
        },
        navList: {
            display: 'none',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%'
        },
        navListOpen: {
            display: 'flex'
        },
        navItem: {
            margin: '5px 0'
        },
        contactButton: {
            marginLeft: 0,
            marginTop: '10px'
        },
        footerContent: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        footerLinks: {
            width: '100%',
            flexDirection: 'column'
        },
        footerColumn: {
            marginLeft: 0,
            marginBottom: '10px'
        },
        footerBottom: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '10px 20px'
        },
        socialLink: {
            marginLeft: '0',
            marginTop: '5px'
        }
    },
    '@media (max-width: 480px)': {
        header: {
            padding: '10px'
        },
        title: {
            fontSize: '20px'
        },
        navLink: {
            fontSize: '16px'
        },
        contactButton: {
            padding: '8px 16px',
            fontSize: '14px'
        },
        footerLeft: {
            maxWidth: '100%'
        },
        footerLogo: {
            height: '30px'
        }
    }
};

export default App;
